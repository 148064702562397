@keyframes marqueeAnim {
  100% {
    transform: translate3d(-10%, 0, 0);
  }
}

.marquee {
  // padding: 37vh 0;
  span {
    opacity: 0.25;
  }

  > div {
    height: 100%;
    width: fit-content;
    align-items: center;
    display: flex;
    position: relative;
    animation: marqueeAnim 15s linear infinite;
    will-change: transform;
  }
}
